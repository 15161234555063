
* {
    box-sizing: border-box;
}

// html {
//     font-size: 62.5%;
// }

body {
    color: $dark-grey;
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    background: $light-grey;
    font-size: $m-size;
    line-height: 1.6;
}

::selection {
    background: $accent_color;
}

::-moz-selection {
    background: $accent_color;
}

button {
    cursor: pointer;
}

button:disabled {
    cursor: default;
}

.is-active {
    font-weight: bold;
}

.Toastify__toast-body{
    font-size: large;
}

th {
    color: #909399;
    font-size: 14px;
    font-weight: bold;
}

td {
    color: #606266;
    font-size: 14px;
    font-weight: normal;
}


.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8f9fa;
}

.table-striped tbody tr {
    cursor: pointer;
    &:hover{
        background-color: darken($color: #f8f9fa, $amount: 5)    
    }
}

.DatePicker__input {
    font-size: medium;
    border: 1px solid #ced4da;
    border-radius: 5px;
    
}

input.DatePicker__input.-ltr {
    width: 100%;
    text-align: start;
    &:focus {
        outline: none;
        border: 2px solid #4169e1
    }
}

div.Calendar.-noFocusOutline.-ltr {
    position: relative;
    margin: 8px
}