.loading__container {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    // width: 100vw;
}

.loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba($accent_color, 0.2);
    border-radius: 50%;
    border-top-color: $accent_color;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    z-index: 1;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}