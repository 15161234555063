.head-root{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #e4e3e3;
    padding: 8px 16px;
}

.back-button {
    border: none;
    font-size: medium;
    border-right: 2px solid #DCDFE6;
    padding: 0px 16px 0px 4px;

}

.head-title {
    margin-left: 16px;
    font-size: medium;
    color: #000000;
}