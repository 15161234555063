.page-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px ;
    font-size: large;
    color: #606266;
}

.item-icon {
    color: #000;
    font-size: 18px;
    &:hover {
        text-decoration: none;
        color: #007BFF;
    }
}

.info-title {
    color: #000;
    font-size: 16px;
    font-weight: bold;
    margin: 0 8px 0 0;
}

.info-value {
    color: #000;
    font-size: 16px;
    font-weight: normal;
}

.error-cell {
    color: red;
}

.subtrial-cell {
    text-transform: uppercase;
}