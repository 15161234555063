.root {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.sidebar {
    width: 200px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 0px;
    }
}

.logout-btn {
    padding: 10px 20px;
    justify-self: center;
    border-radius: 0;
}

.main-content {
    width: 100%;
    height: 100%;
    background-color: white;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 0px;
    }
}