// Colors
$accent_color: #83c2e2;
$color-primary: #4CAF50;
$color-red: #F44336;
$text-color-normal: #212121;
$dark-grey: #333;
$light-grey: #f3f3f3;
$grey: #aaaaaa;
$blue: #1c88bf;
$brown: #795548;
$off-white: #f7f7f7;
$dark-red: #880a1f;
// Font Size
$font-size-xlarge: 2.6rem;
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;
//Font weight
$font-weight-thin: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
// Spacing
$xs-size: 0.8rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
//padding
$spacing-size-large: 0.8rem;
$spacing-size-medium: 0.4rem;
//border
$border-size-thinner: 0.1rem;
$border-size-thin: 0.2rem;

//componentSizing
$header-height: 12rem;
$sidebar-width: 5.5rem;
